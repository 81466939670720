import { combineReducers } from 'redux';
import assetsReducer from './assetsReducer';
import { persistedAuthReducer } from './persistConfig';

const rootReducer = combineReducers({
  assets: assetsReducer,
  auth: persistedAuthReducer
});

export default rootReducer;
