import React from "react";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <script>document.write(new Date().getFullYear())</script> © Kodo Scholarships.
          </div>
          <div class="col-sm-6">
            <div class="text-sm-end d-none d-sm-block">
              Design & Developed by Kodo Technologies
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
