import React, { lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch } from "react-router-dom";
import Layout from "../components/Layout";
import Spinner from "../components/Spinner";
import ProtectedRoute from "./ProtectedRoute";

// import { loadUser } from '../store/actions/userActions';
// import store from '../store';
const Successful = lazy(() => import("../pages/Transactions/Successful"));
const Failed = lazy(() => import("../pages/Transactions/Failed"));
const EditScholarship = lazy(() =>
  import("../pages/Scholarships/EditScholarship")
);
const EditSportsScholarship = lazy(() =>
  import("../pages/Scholarships/EditSportsScholarship")
);
const Published = lazy(() => import("../pages/Scholarships/Published"));
const Unpublished = lazy(() => import("../pages/Scholarships/Unpublished"));
const Plans = lazy(() => import("../pages/Settings/Plans"));
const Profile = lazy(() => import("../pages/Settings/Profile"));
const JobListing = lazy(() => import("../pages/Settings/Categories/Job"));
const Jobs = lazy(() => import("../pages/jobs"));
const AddJob = lazy(() => import("../pages/jobs/AddJobs"));

const Companies = lazy(() => import("../pages/Settings/AdvertiseCompany"));

const EditJob = lazy(() => import("../pages/jobs/EditJob"));
const JobsPublished = lazy(() => import("../pages/jobs/Published"));
const JobsUnPublished = lazy(() => import("../pages/jobs/Unpublished"));

const Login = lazy(() => import("../pages/Auth/Login"));
const UsersListing = lazy(() => import("../pages/Settings/Users"));
const PasswordReset = lazy(() => import("../pages/Auth/PasswordReset"));
const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));

const Dashboard = lazy(() => import("../pages/Dashboard/AdminDashboard"));
const CategoryListing = lazy(() => import("../pages/Settings/Categories"));
const Scholarships = lazy(() => import("../pages/Scholarships"));
const AddScholarship = lazy(() =>
  import("../pages/Scholarships/AddScholarship")
);
const AddSportScholarship = lazy(() =>
  import("../pages/Scholarships/AddSportScholarship")
);
const Posts = lazy(() => import("../pages/posts"));
const AddPost = lazy(() => import("../pages/posts/AddPost"));
const Ads = lazy(() => import("../pages/Settings/AdvertiseCompany/Ads"));

const AppRoot = () => {
  // useEffect(() => {
  //   store.dispatch(loadUser())
  // },[])

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Spinner />
        </div>
      }
    >
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={PasswordReset} />

        <Layout>
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/profile" component={Profile} />
          <ProtectedRoute exact path="/users" component={UsersListing} />
          <ProtectedRoute
            exact
            path="/scholarship/categories"
            component={CategoryListing}
          />
          <ProtectedRoute exact path="/job/categories" component={JobListing} />
          <ProtectedRoute exact path="/scholarships" component={Scholarships} />
          <ProtectedRoute exact path="/jobs" component={Jobs} />
          <ProtectedRoute exact path="/blog/posts" component={Posts} />
          <ProtectedRoute exact path="/add/blog/posts" component={AddPost} />
          <ProtectedRoute
            exact
            path="/edit/blog/posts/:id"
            component={AddPost}
          />

          <ProtectedRoute exact path="/job/add" component={AddJob} />
          <ProtectedRoute
            exact
            path="/scholarship/add"
            component={AddScholarship}
          />
          <ProtectedRoute
            exact
            path="/scholarship/edit/:id"
            component={EditScholarship}
          />
          <ProtectedRoute
            exact
            path="/sports/add"
            component={AddSportScholarship}
          />
          <ProtectedRoute
            exact
            path="/sports/edit/:id"
            component={EditSportsScholarship}
          />
          <ProtectedRoute exact path="/job/edit/:id" component={EditJob} />

          <ProtectedRoute
            exact
            path="/scholarships/published"
            component={Published}
          />
          <ProtectedRoute
            exact
            path="/jobs/published"
            component={JobsPublished}
          />
          <ProtectedRoute
            exact
            path="/jobs/unpublished"
            component={JobsUnPublished}
          />

          <ProtectedRoute
            exact
            path="/scholarships/unpublished"
            component={Unpublished}
          />
          <ProtectedRoute
            exact
            path="/transactions/successful"
            component={Successful}
          />
          <ProtectedRoute exact path="/payment/plans" component={Plans} />

          <ProtectedRoute
            exact
            path="/transactions/failed"
            component={Failed}
          />
          <ProtectedRoute exact path="/companies" component={Companies} />
          <ProtectedRoute exact path="/company/ads" component={Ads} />
        </Layout>
      </Switch>
    </Suspense>
  );
};

export default AppRoot;
