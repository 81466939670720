import React from "react";

const LoadSpinner = () => (
  <div className="d-flex justify-content-center">
    <div style={{
        color: "#1C2755"
      
    }} className="spinner-border" role="status">
    </div>
  </div>
);

export default LoadSpinner;
