import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // or any other storage engine you prefer
import userReducer from './userReducer';
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: null
};

export const persistedAuthReducer = persistReducer(authPersistConfig, userReducer);
