import axios from "axios";
import {
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstants";

import API from "../../helpers/api";

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await API.post("api/v1/auth/admin/login", { email, password });

    if (data.token) {
      localStorage.setItem("token", data.token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user,
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// export const loadUser = () => async (dispatch) => {
//   try {
//     dispatch({ type: LOAD_USER_REQUEST });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//     };

//     const { data } = await API.get("/user/me", config);

//     dispatch({
//       type: LOAD_USER_SUCCESS,
//       payload: data.user,
//     });
//   } catch (error) {
//     console.log("loadUser Failed ====>", error);
//     dispatch({
//       type: LOAD_USER_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

export const logoutUser = () => async (dispatch) => {
  try {
    // await API.get('/user/logout')
    localStorage.removeItem("token");
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
      payload: "Error Logging out User",
    });
  }
};
