import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../store/actions/userActions'
import { useState } from 'react'
import { AiOutlineMenu } from "react-icons/ai"
const Header = () => {

  const dispatch = useDispatch();
  const { user, loading } = useSelector(state => state.auth);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);


  const logoutHandler = () => {
    dispatch(logoutUser());
  }

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  return (
    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <div class="navbar-brand-box" style={{ background: "#1c2755" }}>

            <div
              className="d-lg-none"
              onClick={toggleSidebar}
            >
              <AiOutlineMenu className="d-lg-none" />
              {/* <i style={{ h<eight: "1rem", width: "3rem" }} class=" mdi mdi-menu  d-lg-none"
                            ></i> */}
            </div>
            {isSidebarOpen ? (
              <div style={{ display: "flex", alignItems: "center", backgroundColor: "#fff", height: "27rem", width: "80%", position: "absolute", inset: 0, top: "4rem", borderRadius: "5px", flexDirection: "column", boxShadow: "0 5px 10px rgba(0,0,0,10)", textAlign: "left" }}>
                {/* <div style={{marginLeft:"-15rem", marginTop:"1rem"}}>Dropdown one </div>
                            <div style={{marginLeft:"-15rem", marginTop:"1rem"}}>Dropdown one</div>
                            <div style={{marginLeft:"-15rem", marginTop:"1rem"}}>Dropdown one</div>
                            <div style={{marginLeft:"-15rem", marginTop:"1rem"}}>Dropdown one</div>
                            <div style={{marginLeft:"-15rem", marginTop:"1rem"}}>Dropdown one</div> */}

                <ul style={{ marginLeft: "-6rem", marginTop: "1rem" }} className="metismenu list-unstyled">
                  <li className="menu-title" key="t-menu">
                    Menu
                  </li>

                  <li>
                    <Link to="/dashboard" className="waves-effect">
                      <i className="bx bx-home-circle"></i>
                      <span key="t-dashboards">Dashboard</span>
                    </Link>
                  </li>

                  <li className="menu-title" key="t-apps">
                    Scholarships
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setTwo(!two)}>
                      <i className="bx bx-calendar"></i>
                      <span key="t-dashboards">Transactions</span>
                    </a>
                    <ul className={two ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/transactions/successful" key="t-tui-calendar">
                          Successful
                        </Link>
                      </li>
                      <li>
                        <Link to="/transactions/failed" key="t-full-calendar">
                          Failed
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setOne(!one)}>
                      <i className="bx bx-calendar"></i>
                      <span key="t-dashboards">Scholarships</span>
                    </a>
                    <ul className={one ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/scholarships" key="t-full-calendar">
                          All
                        </Link>
                      </li>
                      <li>
                        <Link to="/scholarships/published" key="t-full-calendar">
                          Published
                        </Link>
                      </li>
                      <li>
                        <Link to="/scholarships/unpublished" key="t-full-calendar">
                          Unpublished
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setSix(!six)}>
                      <i className="bx bx-calendar"></i>
                      <span key="t-dashboards">Jobs</span>
                    </a>
                    <ul className={six ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/jobs/all" key="t-full-calendar">
                          All
                        </Link>
                      </li>
                      <li>
                        <Link to="/jobs/new" key="t-full-calendar">
                          New
                        </Link>
                      </li>
                      <li>
                        <Link to="jobs/published" key="t-full-calendar">
                          Published
                        </Link>
                      </li>
                      <li>
                        <Link to="/jobs/unpublished" key="t-full-calendar">
                          Unpublished
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="menu-title" key="t-pages">
                    Application Settings
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setThree(!three)}>
                      <i className="bx bx-user-circle"></i>
                      <span key="t-authentication">User Management</span>
                    </a>
                    <ul className={three ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/users" key="t-login">
                          Users List
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setFour(!four)}>
                      <i className="bx bx-user-circle"></i>
                      <span key="t-authentication">Payment Plans</span>
                    </a>
                    <ul className={four ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/payment/plans" key="t-login">
                          Payment Settings
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="#" className="has-arrow waves-effect" onClick={() => setFive(!five)}>
                      <i className="bx bx-user-circle"></i>
                      <span key="t-authentication">Categories</span>
                    </a>
                    <ul className={five ? 'sub-menu mm-show' : 'sub-menu mm-collapse'}>
                      <li>
                        <Link to="/scholarship/categories" key="t-login">
                          Scholarship Categories
                        </Link>
                      </li>
                    </ul>
                  </li>
                  
                </ul>
              </div>) : null}



            <Link to="/dashboard" class="logo logo-light">
              {/* <span class="logo-sm">
                        <img src="logo.png" alt="" height="50"/>
                    </span> */}
              <span class="logo-lg">
                <img src="logo.png" alt="" width="150" />
              </span>
            </Link>
          </div>

          <div class="dropdown dropdown-mega d-none d-lg-block ms-2">
            <button type="button" class="btn header-item waves-effect">
              <span key="t-megamenu">Kodo Scholarships Management System - Admin Dashboard</span>
            </button>
            {/* <span>{loading ? user.facility.facilityName: ''}</span> */}
          </div>
        </div>

        <div class="d-flex">
          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

              <img class="rounded-circle header-profile-user"
                src={`https://ui-avatars.com/api/name=${user?.fname}&background=random`}
                alt="User" />

              <span class="d-none d-xl-inline-block ms-1" key="t-henry">{user ? user.lname : ''}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <Link class="dropdown-item" to="/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></Link>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item text-danger" href="#" onClick={logoutHandler}>
                <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger">
                </i> <span key="t-logout">Logout</span>
              </a>
            </div>
          </div>



        </div>
      </div>
    </header>
  )
}

export default Header



