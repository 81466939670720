import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdAssuredWorkload, MdOutlinePayments } from "react-icons/md";
import { TbSchool } from "react-icons/tb";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { BsNewspaper } from "react-icons/bs";
import { PiBuildingsThin } from "react-icons/pi";
const SideBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [sev, setSev] = useState(false);
  const [eght, setEght] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`vertical-menu ${isSidebarOpen ? "sidebar-open" : ""}`}
      style={{ background: "#1c2755" }}
    >
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <button
            type="button"
            className="btn header-item waves-effect d-lg-none"
            onClick={toggleSidebar}
          >
            <i className={`bx ${isSidebarOpen ? "bx-x" : "bx-menu"}`}></i>
          </button>

          <ul className="metismenu list-unstyled">
            <li className="menu-title" key="t-menu">
              Menu
            </li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="bx bx-home-circle"></i>
                <span key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            <li className="menu-title" key="t-apps">
              Scholarships
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setTwo(!two)}
              >
                <i className="bx bx-money"></i>
                {/* <GrTransaction/> */}
                <span key="t-dashboards">Transactions</span>
              </a>
              <ul className={two ? "sub-menu mm-show" : "sub-menu mm-collapse"}>
                <li>
                  <Link to="/transactions/successful" key="t-tui-calendar">
                    Successful
                  </Link>
                </li>
                <li>
                  <Link to="/transactions/failed" key="t-full-calendar">
                    Failed
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setOne(!one)}
              >
                {/* <i className="bx bx-college"></i> */}
                <TbSchool size={18} style={{ marginRight: "10px" }} />

                <span key="t-dashboards">Scholarships</span>
              </a>
              <ul className={one ? "sub-menu mm-show" : "sub-menu mm-collapse"}>
                <li>
                  <Link to="/scholarships" key="t-full-calendar">
                    All
                  </Link>
                </li>
                <li>
                  <Link to="/scholarships/published" key="t-full-calendar">
                    Published
                  </Link>
                </li>
                <li>
                  <Link to="/scholarships/unpublished" key="t-full-calendar">
                    Unpublished
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setSix(!six)}
              >
                <MdAssuredWorkload size={18} style={{ marginRight: "10px" }} />
                <span key="t-dashboards">Jobs</span>
              </a>
              <ul className={six ? "sub-menu mm-show" : "sub-menu mm-collapse"}>
                <li>
                  <Link to="/jobs" key="t-full-calendar">
                    All
                  </Link>
                </li>
                <li>
                  <Link to="/jobs/published" key="t-full-calendar">
                    Published
                  </Link>
                </li>
                <li>
                  <Link to="/jobs/unpublished" key="t-full-calendar">
                    Unpublished
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title" key="t-pages">
              Application Settings
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setThree(!three)}
              >
                <AiOutlineUserAdd size={18} style={{ marginRight: "10px" }} />
                <span key="t-authentication">User Management</span>
              </a>
              <ul
                className={three ? "sub-menu mm-show" : "sub-menu mm-collapse"}
              >
                <li>
                  <Link to="/users" key="t-login">
                    Users List
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setFour(!four)}
              >
                <MdOutlinePayments size={18} style={{ marginRight: "10px" }} />
                <span key="t-authentication">Payment Plans</span>
              </a>
              <ul
                className={four ? "sub-menu mm-show" : "sub-menu mm-collapse"}
              >
                <li>
                  <Link to="/payment/plans" key="t-login">
                    Payment Settings
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setFive(!five)}
              >
                <BiCategory size={18} style={{ marginRight: "10px" }} />
                <span key="t-authentication">Categories</span>
              </a>
              <ul
                className={five ? "sub-menu mm-show" : "sub-menu mm-collapse"}
              >
                <li>
                  <Link to="/scholarship/categories" key="t-login">
                    Scholarship Categories
                  </Link>
                </li>
                <li>
                  <Link to="/job/categories" key="t-login">
                    Job Categories
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setSev(!sev)}
              >
                <BsNewspaper size={18} style={{ marginRight: "10px" }} />
                <span key="t-authentication">blog</span>
              </a>
              <ul className={sev ? "sub-menu mm-show" : "sub-menu mm-collapse"}>
                <li>
                  <Link to="/blog/posts" key="t-login">
                    posts
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#"
                className="has-arrow waves-effect"
                onClick={() => setEght(!eght)}
              >
                <PiBuildingsThin size={18} style={{ marginRight: "10px" }} />
                <span key="t-authentication">Companies</span>
              </a>
              <ul
                className={eght ? "sub-menu mm-show" : "sub-menu mm-collapse"}
              >
                <li>
                  <Link to="/companies" key="t-login">
                    Companies
                  </Link>
                </li>
                <li>
                  <Link to="/company/ads" key="t-login">
                    Company Ads
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
