import React, { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, error, loading } = useSelector((state) => state.auth);
  // const token = localStorage.getItem("token")

  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) => {
            if (isAuthenticated === false) {
                return <Redirect to="/" />
            }
            return <Component {...props} />
        }}
      />
    </Fragment>
  );
};

export default ProtectedRoute;
